@import './../../styles/variables.scss';

.tools {
 background-color: $melon;
 text-align: center;

 .inner-container {
   padding: 30px;
 }

 .down-arrow {
   border-top-color: $nadeshiko-pink;
 }
}
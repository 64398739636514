@import './../../styles/variables.scss';

.nav-bar {
  background-color: $nav-bar;
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  height: 100px;
  align-items: center;
  z-index: 10;

  .nav-link {
    color: $nadeshiko-pink;
    text-decoration: none;
    font-size: 1.75rem;
    font-weight: bold;

    &:hover {
      color: $melon;
      text-transform:uppercase;
    }
  }
}
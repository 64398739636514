$background: #FFEBE6;

$nav-bar: #bf5f39;

$nadeshiko-pink: #EBADC6;

$telemagenta: #CD3170;

$melon: #FFD0C5;

$grey: #DCE5E5;
@import './styles/variables.scss';

.app {
  width: 100%;
  font-family: 'League Spartan', sans-serif;

  section {
    scroll-margin-top: 4rem;
  }
}

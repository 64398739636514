@import './../../styles/variables.scss';

.about {
  background-color: $nadeshiko-pink;

  .down-arrow{
    border-top-color: $background;
  }

  .profile-pic {
    height: 300px;
    border-radius: 50%;
    margin-right: 50px;
    object-fit: contain;
  }

  .MuiTypography-h1 {
    text-align: center;
    padding-top: 30px;
  }

  .inner-container {
    display: flex;
    justify-content: center;
    padding: 30px;
  }

  .about-summary {
    width: 60%;
  }

  @media only screen and (max-width: 600px) {
    .inner-container {
      flex-direction: column;

      .profile-pic {
        margin: 0 auto;
      }

      .about-summary {
        margin: 20px auto;
        width: 90%;
      }
    }
  }
}
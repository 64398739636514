.project {
  width: 500px;
  margin: 0px 10px 40px 0px;
  text-align: center;

  .project-tech {
    display: flex; 
    justify-content: space-around;
  }

  @media only screen and (max-width: 600px) {
      width: 100%;
  }
}
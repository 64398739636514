@import './../../styles/variables.scss';

.contact {
  background-color: $grey;
  text-align: center;

  .social-icons {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin: 0 auto;
  }

  .MuiInput-root {
    margin-top: 30px;
  }

  .contact-form {
    width: 50%;
    padding: 50px;
    margin: 0 auto;

    .form-field {
      width: 90%;
    }

    .send-btn-container {
      display: flex;
      justify-content: end;
      margin: 20px 30px;
    }

    .MuiAlert-root {
      margin: 30px;
    }
  }
  
  .MuiButton-outlined {
    border: 2px solid $telemagenta;
    color: $telemagenta;
    font-size: 1rem;
    font-weight: bold;

    &:hover {
      border: 2px solid $telemagenta;
      color: $telemagenta;
      background-color: $melon;
    }
  }

  .inner-container {
    padding: 30px;
  }

  .down-arrow {
    border-top-color: $telemagenta;
  }

  @media only screen and (max-width: 600px) {
    .contact-form {
      width: 80%;
      padding: 20px;

      .MuiAlert-root {
        margin: 10px;
      }
    }
  }
}
@import './../../styles/variables.scss';

.projects {
  background-color: $telemagenta;
  text-align: center;

  .MuiTypography-h1 {
    color: $melon;
  }

  .inner-container {
    padding: 30px;
  }

  .cards-container {
    padding: 30px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 80%;
    justify-content: space-around;
  }

   .down-arrow {
   border-top-color: $melon;
 }

  @media only screen and (max-width: 600px) {
    .cards-container {
      flex-direction: column;
      width: 100%;

      .project {
        align-self: center;
        margin-bottom: 20px;
      }
    }
  }
}